@media screen and (min-width: 320px){
    .cards2-domaines-right{
        margin: 20px 10px 200px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 300px;
        height: 200px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 150px;
        left: -25px;
        position: relative;
        width: 85%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        color: #0c66ba;
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}
@media screen and (min-width: 480px){
    .cards2-domaines-right{
        margin: 30px 25px 225px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 400px;
        height: 200px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 150px;
        left: -75px;
        position: relative;
        width: 75%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}
@media screen and (min-width: 768px){
    .cards2-domaines-right{
        margin: 30px 125px 350px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 600px;
        height: 300px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 225px;
        left: -200px;
        position: relative;
        width: 60%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}
@media screen and (min-width: 1024px){
    .cards2-domaines-right{
        margin: 30px 125px 350px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 600px;
        height: 300px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 225px;
        left: -400px;
        position: relative;
        width: 40%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}
@media screen and (min-width: 1200px){
    .cards2-domaines-right{
        margin: 30px 125px 350px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 600px;
        height: 300px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 225px;
        left: -400px;
        position: relative;
        width: 40%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}
@media screen and (min-width: 1440px){
    .cards2-domaines-right{
        margin: 30px 125px 350px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 650px;
        height: 350px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 275px;
        left: -400px;
        position: relative;
        width: 35%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}
@media screen and (min-width: 1750px){
    .cards2-domaines-right{
        margin: 30px 150px 350px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 700px;
        height: 400px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 325px;
        left: -400px;
        position: relative;
        width: 35%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}
@media screen and (min-width: 1920px){
    .cards2-domaines-right{
        margin: 50px 150px 350px 0;
        display: flex;
        flex-direction: row-reverse;
    }

    .cards2-domaines-img-right{
        width: 700px;
        height: 400px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }

    .cards2-domaines-container-right{
        top: 325px;
        left: -400px;
        position: relative;
        width: 35%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }

    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
    }
}