@media screen and (min-width: 320px){
    .domaines-body{
        height: 1350px;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
    }
    
    #domaines-titre{
        color: white;
        padding: 35px 0 30px 35px;
    }
    
    .cards-domaines-maincontainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 1250px;
    }

    .cards-domaines-line{
        height: 2.5px;
        width: 200px;
        background-color: whitesmoke;
        margin: 30px 0 30px 0;
    }

}
@media screen and (min-width: 480px){
    .domaines-body{
        height: 1400px;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
    }
    
    #domaines-titre{
        color: white;
        padding: 35px 0 30px 70px;
    }
    
    .cards-domaines-maincontainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 1300px;
        margin: 0;
    }

    .cards-domaines-line{
        height: 3px;
        width: 200px;
        background-color: whitesmoke;
        margin: 30px 0 30px 0;
    }

}
@media screen and (min-width: 768px){
    .domaines-body{
        height: 2050px;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
    }
    
    #domaines-titre{
        color: white;
        padding: 50px 0 30px 170px;
        font-size: 2rem;
    }
    
    .cards-domaines-maincontainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 1850px;
        margin: 0;
    }

    .cards-domaines-line{
        height: 5px;
        width: 250px;
        background-color: whitesmoke;
        margin: 30px 0 30px 0;
    }

}
@media screen and (min-width: 1024px){
    .domaines-body{
        height: 1200px;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
    }
    
    #domaines-titre{
        color: white;
        padding: 60px 0 30px 300px;
        font-size: 2rem;
    }
    
    .cards-domaines-maincontainer{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 1000px;
        margin: 0;
    }

    .cards-domaines-line{
        height: 300px;
        width: 3px;
        background-color: whitesmoke;
        margin: 0 50px 0 50px;
    }
    .cards-domaines-line:nth-child(4){
        visibility: collapse;
    }

}
@media screen and (min-width: 1200px){
    .domaines-body{
        height: 500px;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
    }
    
    #domaines-titre{
        color: white;
        padding: 60px 0 30px 230px;
        font-size: 1.5rem;
    }
    
    .cards-domaines-maincontainer{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 350px;
        margin: 0;
    }

    .cards-domaines-line{
        height: 220px;
        width: 2px;
        color: whitesmoke;
    }
    .cards-domaines-line:nth-child(4){
        visibility: visible;
    }
}
@media screen and (min-width: 1440px){
    .domaines-body{
        height: 1000px;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
    }
    
    #domaines-titre{
        color: white;
        padding: 55px 0 28px 250px;
        font-size: 1.5rem;
    }
    
    .cards-domaines-maincontainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 375px;
        margin: 0;
    }

    .cards-domaines-line{
        height: 240px;
        width: 2px;
        color: whitesmoke;

    }

}
@media screen and (min-width: 1920px){
    .domaines-body{
        height: 700px;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
    }
    
    #domaines-titre{
        color: white;
        padding: 70px 0 0 550px;
        font-size: 2rem;
    }
    
    .cards-domaines-maincontainer{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 550px;
        margin: 0;
    }

    .cards-domaines-line{
        height: 300px;
        width: 2px;
        color: white;
    }

}