@media screen and (min-width:320px){
    #collapse{
        height: 0;
        opacity: 0;
        transition: all 0.5s;
    }
    #collapse.hide{
        height: 525px;
        opacity: 1;
        overflow: hidden;
    }
    .formcontact-button{
        padding: 7px;
        font-size: 1.2rem;
        font-weight: bold;
        color: #0c66ba;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
        font-weight: bold;
    }
    .formcontact-form{
        width: 100%;
        border-radius: 15px;
    }
    form .formcontact-form-row{
        display: flex;
        margin: 50px 0px;
    }
    form .formcontact-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .formcontact-form-row .textarea{
        height: 200px;
    }
}
@media screen and (min-width:480px){
    #collapse{
        height: 0;
        opacity: 0;
        transition: all 0.5s;
    }
    #collapse.hide{
        height: 525px;
        opacity: 1;
        overflow: hidden;
    }
    .formcontact-button{
        padding: 5px;
        color: #0c66ba;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
    }
    .formcontact-form{
        width: 100%;
        border-radius: 15px;
    }
    form .formcontact-form-row{
        display: flex;
        margin: 50px 0px;
    }
    form .formcontact-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .formcontact-form-row .textarea{
        height: 200px;
    }
}
@media screen and (min-width:768px){
    #collapse{
        height: 0;
        opacity: 0;
        transition: all 0.5s;
    }
    #collapse.hide{
        height: 525px;
        opacity: 1;
        overflow: hidden;
    }
    .formcontact-button{
        padding: 5px;
        color: #0c66ba;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
    }
    .formcontact-form{
        width: 100%;
        border-radius: 15px;
    }
    form .formcontact-form-row{
        display: flex;
        margin: 50px 0px;
    }
    form .formcontact-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .formcontact-form-row .textarea{
        height: 200px;
    }
}
@media screen and (min-width:1024px){
    #collapse{
        height: 0;
        opacity: 0;
        transition: all 0.5s;
    }
    #collapse.hide{
        height: 525px;
        opacity: 1;
        overflow: hidden;
    }
    .formcontact-button{
        padding: 5px;
        color: #0c66ba;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
    }
    .formcontact-form{
        width: 100%;
        border-radius: 15px;
    }
    form .formcontact-form-row{
        display: flex;
        margin: 50px 0px;
    }
    form .formcontact-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .formcontact-form-row .textarea{
        height: 200px;
    }
}
@media screen and (min-width:1200px){
    #collapse{
        height: 0;
        opacity: 0;
        transition: all 0.5s;
    }
    #collapse.hide{
        opacity: 1;
        overflow: hidden;
    }
    .formcontact-button{
        padding: 8px;
        font-size: 1.3rem;
        font-weight: bold;
        color: #0c66ba;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
    }
    .formcontact-form{
        width: 60%;
        border-radius: 15px;
    }
    form .formcontact-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .formcontact-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .formcontact-form-row .textarea{
        height: 200px;
    }
}
@media screen and (min-width:1440px){
    #collapse{
        height: 0;
        opacity: 0;
        transition: all 0.5s;
    }
    #collapse.hide{
        opacity: 1;
        overflow: hidden;
        height: 600px;
    }
    .formcontact-button{
        padding: 9px;
        font-size: 1.4rem;
        font-weight: bold;
        color: #0c66ba;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
    }
    .formcontact-form{
        width: 60%;
        border-radius: 15px;
    }
    form .formcontact-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .formcontact-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .formcontact-form-row .textarea{
        height: 200px;
    }
}
@media screen and (min-width:1920px){
    #collapse{
        height: 0;
        opacity: 0;
        transition: all 0.5s;
    }
    #collapse.hide{
        opacity: 1;
        overflow: hidden;
    }
    .formcontact-button{
        padding: 10px;
        font-size: 1.5rem;
        font-weight: bold;
        color: #0c66ba;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
    }
    .formcontact-form{
        width: 80%;
        border-radius: 15px;
    }
    form .formcontact-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .formcontact-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .formcontact-form-row .textarea{
        height: 200px;
    }
    form .formcontact-form-row label{
        font-size: 1.2rem;
    }
}