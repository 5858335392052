.contactus-para a{
    text-decoration: none;
    color: #0c66ba;
}

@media screen and (min-width: 320px){
    .contactus-body{
        margin: 30px auto;
        width: 90%;
        background-color: white;
        border-radius: 15px;
        box-shadow: 1px 5px 5px black;
        color: #0c66ba;
    }
    #contactus-titre{
        text-align: center;
        padding: 15px 0 0 0;
    }
    #contactus-text{
        text-align: center;
        padding: 17px 12px 0 12px;
    }
    #contactus-icons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 120px;
    }
    .contactus-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 30px;
    }
    .icons{
        width: 25px;
        height: 25px;
        margin-right: 13px;
    }
    .contactus-para{
        display: flex;
        align-items: center;
    }
}
@media screen and (min-width: 480px){
    .contactus-body{
        margin: 30px auto;
        width: 90%;
        background-color: white;
        border-radius: 15px;
        box-shadow: 1px 5px 5px black;
    }
    #contactus-titre{
        text-align: center;
        padding: 20px 0 0 0;
    }
    #contactus-text{
        text-align: center;
        padding: 20px 12px 0 12px;
    }
    #contactus-icons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 130px;
    }
    .contactus-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 30px;
    }
    .icons{
        width: 25px;
        height: 25px;
        margin-right: 13px;
    }
    .contactus-para{
        display: flex;
        align-items: center;
    }
}
@media screen and (min-width: 768px){
    .contactus-body{
        margin: 30px auto;
        width: 90%;
        background-color: white;
        border-radius: 15px;
        box-shadow: 1px 5px 5px black;
    }
    #contactus-titre{
        text-align: center;
        padding: 20px 0 0 0;
        font-size: 2rem;
    }
    #contactus-text{
        text-align: center;
        font-size: larger;
        padding: 20px 12px 0 12px;
    }
    #contactus-icons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 170px;
    }
    .contactus-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 55px;
    }
    .icons{
        width: 40px;
        height: 40px;
        margin-right: 13px;
    }
    .contactus-para{
        display: flex;
        align-items: center;
        font-size: larger;
    }
}
@media screen and (min-width: 1024px){
    .contactus-body{
        margin: 40px auto;
        width: 90%;
        background-color: white;
        border-radius: 15px;
        box-shadow: 1px 5px 5px black;
    }
    #contactus-titre{
        text-align: center;
        padding: 25px 0 0 0;
        font-size: 2rem;
    }
    #contactus-text{
        text-align: center;
        font-size: 1.4rem;
        padding: 20px 15px 0 15px;
    }
    #contactus-icons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 170px;
    }
    .contactus-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 70px;
    }
    .icons{
        width: 50px;
        height: 50px;
        margin-right: 13px;
    }
    .contactus-para{
        display: flex;
        align-items: center;
        font-size: 1.4rem;
    }
}
@media screen and (min-width: 1200px){
    .contactus-body{
        margin: 60px auto;
        padding: 30px;
        width: 90%;
        background-color: #0c66ba;
        color: white;
        border-radius: 15px;
        box-shadow: 1px 5px 5px black;
    }
    #contactus-titre{
        padding: 0;
        font-size: 2rem;
    }
    #contactus-text{
        padding: 18px 12px 12px 12px;
        font-size: 1.2rem;
    }
    #contactus-icons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100px;
    }
    .contactus-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 28px;
    }
    .icons{
        width: 25px;
        height: 25px;
        margin-right: 12px;
        filter: brightness(0) invert(1);
    }
    .contactus-para{
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: white;
    }
    .contactus-para a{
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: white;
    }
}
@media screen and (min-width: 1440px){
    .contactus-body{
        margin: 60px auto;
        padding: 30px;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px black;
    }
    #contactus-titre{
        padding: 0;
        font-size: 2rem;
    }
    #contactus-text{
        padding: 18px 12px 12px 12px;
        font-size: 1.3rem;
    }
    #contactus-icons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 120px;
    }
    .contactus-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 30px;
    }
    .icons{
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }
    .contactus-para{
        display: flex;
        align-items: center;
        font-size: 1.3rem;
    }
    .contactus-para a{
        display: flex;
        align-items: center;
        font-size: 1.3rem;
    }
}
@media screen and (min-width: 1920px){
    .contactus-body{
        margin: 80px auto;
        padding: 30px;
        width: 90%;
        background-color: #0c66ba;
        border-radius: 15px;
        box-shadow: 1px 5px 5px black;
        color: whitesmoke;
    }
    #contactus-titre{
        padding: 0;
        font-size: 2.2rem;
    }
    #contactus-text{
        padding: 25px 15px 15px 15px;
        font-size: 1.6rem;
        color: whitesmoke;
    }
    #contactus-icons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 150px;
    }
    .contactus-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 40px;
        color: whitesmoke;
    }
    .icons{
        width: 40px;
        height: 40px;
        margin-right: 12px;
        filter: brightness(0) invert(1);
    }
    .contactus-para{
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        color: whitesmoke;
    }
    .contactus-para a{
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        color: whitesmoke;
    }
}