*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  position: relative;
  /*background: url("../img/background/line_-_46956-_Original_.gif") repeat-x;*/
  background: url("../img/background/white-background.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  /*background-color: white;*/
  width: 100%;
}

/*
.app{
  --size: 250px;
  --speed: 55s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 10));
  background-image: linear-gradient(#0b65ba, #0b65ba);
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  position: fixed;
  top: 25%;
  right: 0;
  width: 40%;
  height: 65%;
  z-index: -1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
*/
@media screen and (min-width: 320px){
  body:after{
    content:"";
    position: relative; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 800px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    /*background: url("../img/background/line_-_46956-_Original_.gif") center center;
    */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: white;
  }
}
