@media screen and (min-width: 320px){

    .pagecontactus{
        height: 100%;
    }
    .pagecontactus-container{
        background-color: #0c66ba;
    }
    .pagecontactus-container-body{
        padding: 100px 0 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .pagecontactus-title{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .pagecontactus-title h1{
        margin-top: 40px;
        padding: 10px;
    }
    /* --- FORMULAIRE --- */
    .pagecontactus-form{
        width: 100%;
        border-radius: 15px;
    }
    form .pagecontactus-form-select{
        display: flex;
        flex-direction: column;
        margin: 50px 20px;
    }
    form .pagecontactus-form-select label{
        margin: 10px 20px;
        color: white;
    }
    form .pagecontactus-form-select select{
        width: 50%;
        margin: 0 20px;
        text-align: center;
        border: none;
        height: 20px;
    }
    form .pagecontactus-form-select option{
        border: none;
    }
    form .pagecontactus-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .pagecontactus-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .pagecontactus-form-row .textarea{
        height: 200px;
    }
    .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    .input-send{
        margin: 0 0 0 25px;
        width: 25%;
        font-size: 1.1rem;
    }
    .input-data input,
    .textarea textarea{
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      background-color: transparent;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label,
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
      transform: translateY(-30px);
      font-size: 14px;
      color: white;
    }
    .textarea textarea{
        resize: none;
        padding-top: 10px;
    }
    .input-data label{
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        color: white;
    }
    .textarea label{
        width: 100%;
        bottom: 170px;
    }
    .input-data .underline{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
    }
    .input-data .underline:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .input-data input:focus ~ .underline:before,
    .input-data input:valid ~ .underline:before,
    .textarea textarea:focus ~ .underline:before,
    .textarea textarea:valid ~ .underline:before{
      transform: scale(1);
    }    
    
}
@media screen and (min-width: 480px){

    .pagecontactus{
        height: 100%;
    }
    .pagecontactus-container{
        background-color: #0c66ba;
    }
    .pagecontactus-container-body{
        padding: 100px 0 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .pagecontactus-title{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .pagecontactus-title h1{
        margin-top: 40px;
        padding: 10px 20px 10px 20px;
    }
    /* --- FORMULAIRE --- */
    .pagecontactus-form{
        width: 100%;
        border-radius: 15px;
    }
    form .pagecontactus-form-select{
        display: flex;
        flex-direction: column;
        margin: 50px 20px;
    }
    form .pagecontactus-form-select label{
        margin: 10px 20px;
        color: white;
    }
    form .pagecontactus-form-select select{
        width: 40%;
        margin: 0 20px;
        text-align: center;
    }
    form .pagecontactus-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .pagecontactus-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .pagecontactus-form-row .textarea{
        height: 200px;
    }
    .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    .input-send{
        margin: 0 0 0 25px;
        width: 20%;
        font-size: 1.1rem;
    }
    .input-data input,
    .textarea textarea{
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      background-color: transparent;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label,
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
      transform: translateY(-30px);
      font-size: 14px;
      color: white;
    }
    .textarea textarea{
        resize: none;
        padding-top: 10px;
    }
    .input-data label{
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        color: white;
    }
    .textarea label{
        width: 100%;
        bottom: 170px;
    }
    .input-data .underline{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
    }
    .input-data .underline:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .input-data input:focus ~ .underline:before,
    .input-data input:valid ~ .underline:before,
    .textarea textarea:focus ~ .underline:before,
    .textarea textarea:valid ~ .underline:before{
      transform: scale(1);
    }    
    
}
@media screen and (min-width: 768px){

    .pagecontactus{
        height: 100%;
    }
    .pagecontactus-container{
        background-color: #0c66ba;
    }
    .pagecontactus-container-body{
        padding: 100px 0 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .pagecontactus-title{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .pagecontactus-title h1{
        margin-top: 40px;
        padding: 10px 90px 10px 90px;
    }
    /* --- FORMULAIRE --- */
    .pagecontactus-form{
        width: 100%;
        border-radius: 15px;
    }
    form .pagecontactus-form-select{
        display: flex;
        flex-direction: column;
        margin: 50px 30px;
    }
    form .pagecontactus-form-select label{
        margin: 10px 20px;
        color: white;
    }
    form .pagecontactus-form-select select{
        width: 35%;
        margin: 0 20px;
        text-align: center;
    }
    form .pagecontactus-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .pagecontactus-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 30px;
        position: relative;
    }
    form .pagecontactus-form-row .textarea{
        height: 200px;
    }
    .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    .input-send{
        margin: 0 0 0 25px;
        width: 15%;
        font-size: 1.1rem;
    }
    .input-data input,
    .textarea textarea{
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      background-color: transparent;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label,
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
      transform: translateY(-30px);
      font-size: 14px;
      color: white;
    }
    .textarea textarea{
        resize: none;
        padding-top: 10px;
    }
    .input-data label{
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        color: white;
    }
    .textarea label{
        width: 100%;
        bottom: 170px;
    }
    .input-data .underline{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
    }
    .input-data .underline:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .input-data input:focus ~ .underline:before,
    .input-data input:valid ~ .underline:before,
    .textarea textarea:focus ~ .underline:before,
    .textarea textarea:valid ~ .underline:before{
      transform: scale(1);
    }    
    
}
@media screen and (min-width: 1024px){

    .pagecontactus{
        height: 100%;
    }
    .pagecontactus-container{
        background-color: #0c66ba;
    }
    .pagecontactus-container-body{
        padding: 100px 0 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .pagecontactus-title{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .pagecontactus-title h1{
        margin-top: 40px;
        padding: 10px 90px 10px 90px;
    }
    /* --- FORMULAIRE --- */
    .pagecontactus-form{
        width: 100%;
        border-radius: 15px;
    }
    form .pagecontactus-form-select{
        display: flex;
        flex-direction: column;
        margin: 50px 30px;
    }
    form .pagecontactus-form-select label{
        margin: 10px 20px;
        color: white;
    }
    form .pagecontactus-form-select select{
        width: 35%;
        margin: 0 20px;
        text-align: center;
    }
    form .pagecontactus-form-row{
        display: flex;
        margin: 50px 30px;
    }
    form .pagecontactus-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 30px;
        position: relative;
    }
    form .pagecontactus-form-row .textarea{
        height: 200px;
    }
    .input-data{
        width: 100%;
        height: 40px;
        margin: 0 30px;
        position: relative;
    }
    .input-send{
        margin: 0 0 0 25px;
        width: 15%;
        font-size: 1.1rem;
    }
    .input-data input,
    .textarea textarea{
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      background-color: transparent;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label,
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
      transform: translateY(-30px);
      font-size: 14px;
      color: white;
    }
    .textarea textarea{
        resize: none;
        padding-top: 10px;
    }
    .input-data label{
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        color: white;
    }
    .textarea label{
        width: 100%;
        bottom: 170px;
    }
    .input-data .underline{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
    }
    .input-data .underline:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .input-data input:focus ~ .underline:before,
    .input-data input:valid ~ .underline:before,
    .textarea textarea:focus ~ .underline:before,
    .textarea textarea:valid ~ .underline:before{
      transform: scale(1);
    }    
    
}

@media screen and (min-width: 1200px){

    .pagecontactus{
        height: 100%;
    }
    .pagecontactus-container{
        background-color: #0c66ba;
    }
    .pagecontactus-container-body{
        padding: 100px 0 20px 0;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .pagecontactus-title{
        display: flex;
        justify-content: center;
        width: 45%;
    }
    .pagecontactus-title h1{
        margin-top: 150px;
        padding: 10px 30px 10px 30px;
    }
    /* --- FORMULAIRE --- */
    .pagecontactus-form{
        width: 55%;
        border-radius: 15px;
    }
    form .pagecontactus-form-select{
        display: flex;
        flex-direction: column;
        margin: 50px 20px;
    }
    form .pagecontactus-form-select label{
        margin: 10px 20px;
        color: white;
    }
    form .pagecontactus-form-select select{
        width: 35%;
        margin: 0 20px;
        text-align: center;
        border: none;
        height: 20px;
    }
    form .pagecontactus-form-select option{
        border: none;
    }
    form .pagecontactus-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .pagecontactus-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .pagecontactus-form-row .textarea{
        height: 200px;
    }
    .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    .input-send{
        margin: 0 0 0 25px;
        font-size: 1rem;
    }
    .input-data input,
    .textarea textarea{
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      background-color: transparent;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label,
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
      transform: translateY(-30px);
      font-size: 14px;
      color: white;
    }
    .textarea textarea{
        resize: none;
        padding-top: 10px;
    }
    .input-data label{
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        color: white;
    }
    .textarea label{
        width: 100%;
        bottom: 170px;
    }
    .input-data .underline{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
    }
    .input-data .underline:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .input-data input:focus ~ .underline:before,
    .input-data input:valid ~ .underline:before,
    .textarea textarea:focus ~ .underline:before,
    .textarea textarea:valid ~ .underline:before{
      transform: scale(1);
    }    
    
}
@media screen and (min-width: 1440px){

    .pagecontactus{
        height: 100%;
    }
    .pagecontactus-container{
        background-color: #0c66ba;
    }
    .pagecontactus-container-body{
        padding: 100px 0 20px 0;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .pagecontactus-title{
        display: flex;
        justify-content: center;
        width: 40%;
    }
    .pagecontactus-title h1{
        margin-top: 150px;
        padding: 10px 30px 10px 30px;
    }
    /* --- FORMULAIRE --- */
    .pagecontactus-form{
        width: 60%;
        border-radius: 15px;
    }
    form .pagecontactus-form-select{
        display: flex;
        flex-direction: column;
        margin: 50px 20px;
    }
    form .pagecontactus-form-select label{
        margin: 10px 20px;
        color: white;
    }
    form .pagecontactus-form-select select{
        width: 35%;
        margin: 0 20px;
        text-align: center;
    }
    form .pagecontactus-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .pagecontactus-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .pagecontactus-form-row .textarea{
        height: 200px;
    }
    .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    .input-send{
        margin: 0 0 0 25px;
        font-size: 1.1rem;
    }
    .input-data input,
    .textarea textarea{
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      background-color: transparent;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label,
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
      transform: translateY(-30px);
      font-size: 14px;
      color: white;
    }
    .textarea textarea{
        resize: none;
        padding-top: 10px;
    }
    .input-data label{
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        color: white;
    }
    .textarea label{
        width: 100%;
        bottom: 170px;
    }
    .input-data .underline{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
    }
    .input-data .underline:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .input-data input:focus ~ .underline:before,
    .input-data input:valid ~ .underline:before,
    .textarea textarea:focus ~ .underline:before,
    .textarea textarea:valid ~ .underline:before{
      transform: scale(1);
    }    
    
}
@media screen and (min-width: 1920px){

    .pagecontactus{
        height: 100%;
    }
    .pagecontactus-container{
        background-color: #075787;
    }
    .pagecontactus-container-body{
        padding: 100px 0 20px 0;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .pagecontactus-title{
        display: flex;
        justify-content: center;
        width: 40%;
    }
    .pagecontactus-title h1{
        margin-top: 150px;
        padding: 10px 90px 10px 90px;
    }
    /* --- FORMULAIRE --- */
    .pagecontactus-form{
        width: 60%;
        border-radius: 15px;
    }
    form .pagecontactus-form-select{
        display: flex;
        flex-direction: column;
        margin: 50px 20px;
    }
    form .pagecontactus-form-select label{
        margin: 10px 20px;
        color: white;
        font-size: 1.2rem;
    }
    form .pagecontactus-form-select select{
        width: 25%;
        margin: 0 20px;
        text-align: center;
    }
    form .pagecontactus-form-row{
        display: flex;
        margin: 50px 20px;
    }
    form .pagecontactus-form-row .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    form .pagecontactus-form-row .textarea{
        height: 200px;
    }
    .input-data{
        width: 100%;
        height: 40px;
        margin: 0 20px;
        position: relative;
    }
    .input-send{
        margin: 0 0 0 25px;
        width: 10%;
    }
    .input-data input,
    .textarea textarea{
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 17px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      background-color: transparent;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label,
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
      transform: translateY(-30px);
      font-size: 14px;
      color: white;
    }
    .textarea textarea{
        resize: none;
        padding-top: 10px;
    }
    .input-data label{
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        font-size: 1.2rem;
        transition: all 0.3s ease;
        color: white;
    }
    .textarea label{
        width: 100%;
        bottom: 170px;
    }
    .input-data .underline{
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
    }
    .input-data .underline:before{
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.3s ease;
    }
    .input-data input:focus ~ .underline:before,
    .input-data input:valid ~ .underline:before,
    .textarea textarea:focus ~ .underline:before,
    .textarea textarea:valid ~ .underline:before{
      transform: scale(1);
    }    
    
}