@media screen and (min-width: 320px){

    .barselectexpertises-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 80px;
    }
    .boxexpertises-maincontainer{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        transition: all 0.25s ease-out;
    }
    .boxexpertises-container:hover{
        background-color: #0c66ba;
    }
    .boxexpertises-container:hover h4{
        color: white;
    }
    
    .boxexpertises-container h4{
        color: #0c66ba;
        text-align: center;
        font-size: 0.65rem;
        padding: 10px;
    }
    
    .boxexpertises_container_link{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise{
        background-color: #0c66ba;
    
    }
    .activeexpertise h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}


@media screen and (min-width: 480px){

    .barselectexpertises-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 90px;
    }
    .boxexpertises-maincontainer{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        transition: all 0.25s ease-out;
    }
    .boxexpertises-container:hover{
        background-color: #0c66ba;
    }
    .boxexpertises-container:hover h4{
        color: white;
    }
    
    .boxexpertises-container h4{
        color: #0c66ba;
        text-align: center;
        font-size: 0.9rem;
        padding: 10px;
    }
    
    .boxexpertises_container_link{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise{
        background-color: #0c66ba;
    
    }
    .activeexpertise h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}

@media screen and (min-width: 768px){

    .barselectexpertises-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 85px;
    }
    .boxexpertises-maincontainer{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        transition: all 0.25s ease-out;
    }
    .boxexpertises-container:hover{
        background-color: #0c66ba;
    }
    .boxexpertises-container:hover h4{
        color: white;
    }
    
    .boxexpertises-container h4{
        color: #0c66ba;
        text-align: center;
        padding: 10px;
    }
    
    .boxexpertises_container_link{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise{
        background-color: #0c66ba;
    
    }
    .activeexpertise h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}


@media screen and (min-width: 1024px){

    .barselectexpertises-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 75px;
    }
    .boxexpertises-maincontainer{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        transition: all 0.25s ease-out;
    }
    .boxexpertises-container:hover{
        background-color: #0c66ba;
    }
    .boxexpertises-container:hover h4{
        color: white;
    }
    
    .boxexpertises-container h4{
        color: #075787;
        text-align: center;
        padding: 10px;
    }
    
    .boxexpertises_container_link{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise{
        background-color: #0c66ba;
    
    }
    .activeexpertise h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}


@media screen and (min-width: 1200px){

    .barselectexpertises-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100px;
    }
    .boxexpertises-maincontainer{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        position: relative;
        overflow: hidden;
    }
    /*
    .boxexpertises-container:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container:hover h4{
        text-shadow: 1px 1px 2px #0c66ba;
    }
    
    .boxexpertises-container h4{
        color: #075787;
        text-align: center;
        padding: 10px;
        font-size: 1.3rem;
        transition: all 0.2s;
        opacity: 1;
        position: absolute;
    }
    .boxexpertises_container_link{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise{
        background-color: #0c66ba;
    }
    .activeexpertise h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}

@media screen and (min-width: 1440px){

    .barselectexpertises-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 75px;
    }
    .boxexpertises-maincontainer{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        transition: all 0.25s ease-out;
    }
    .boxexpertises-container:hover{
        background-color: #0c66ba;
    }
    .boxexpertises-container:hover h4{
        color: white;
    }
    
    .boxexpertises-container h4{
        color: #0c66ba;
        text-align: center;
        font-size: large;
        padding: 10px;
    }
    
    .boxexpertises_container_link{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise{
        background-color: #0c66ba;
    
    }
    .activeexpertise h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

    
}

@media screen and (min-width: 1920px){

    .barselectexpertises-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 75px;
    }
    .boxexpertises-maincontainer{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        transition: all 0.25s ease-out;
    }
    .boxexpertises-container:hover{
        background-color: #0c66ba;
    }
    .boxexpertises-container:hover h4{
        color: white;
    }
    
    .boxexpertises-container h4{
        color: #075787;
        text-align: center;
        font-size: larger;
        padding: 10px;
    }
    
    .boxexpertises_container_link{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise{
        background-color: #0c66ba;
    
    }
    .activeexpertise h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

    
}

