.pageexpertises-check-logo{
    height: 35px;
    padding: 2px;
    background-color: white;
    border-radius: 3px;
    margin: 5px 10px 20px 0;
}
.cardspageexpertises-cardsbody-check-logo{
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: first baseline;
}
.cardspageexpertises-cardsbody-check-logo p{
    color: black;
}



@media screen and (min-width: 320px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspageexpertises-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0c66ba;
    }

    .cardspageexpertises-cards{
        width: 95%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspageexpertises-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspageexpertises-cardsheader img{
        width: 80px;
        height: 80px;
    }
    .cardspageexpertises-cardsheader h1{
        color: white;
        font-size: 1.8rem;
        text-align: center;
        width: 60%;
    }
    .pageexpertises-logo{
        margin: 15px 15px 15px 15px;
    }
    .cardspageexpertises-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    .cardspageexpertises-cardsbody-text{
        width: 100%;
        padding: 30px 35px;
    }
    .cardspageexpertises-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.1rem;
    }

    .cardspageexpertises-cardsbody-check{
        width: 100%;
        padding: 30px 35px;
    }
    .cardspageexpertises-cardsbody-check h3{
        color: white;
    }
    .cardspageexpertises-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspageexpertises-cardsbody-textlist li{
        margin: 8px 0 8px 0;
    }
    .cardspageexpertises-cardsbody-checklist li{
        position: relative;
        list-style-type: none;
        padding-left: 2.5rem;
        margin-bottom: 0.5rem;
        color: white;
    }
    .cardspageexpertises-cardsbody-checklist li:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -2px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: white;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }

    .cardspageexpertises-seperate{
        width: 200px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }
}

@media screen and (min-width: 480px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspageexpertises-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cardspageexpertises-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspageexpertises-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspageexpertises-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspageexpertises-cardsheader h1{
        color: white;
        font-size: 1.8rem;
        text-align: center;
        width: 60%;
    }
    .pageexpertises-logo{
        margin: 15px 15px 15px 15px;
    }
    .cardspageexpertises-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    .cardspageexpertises-cardsbody-text{
        width: 100%;
        padding: 30px 60px;
    }
    .cardspageexpertises-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.1rem;
    }

    .cardspageexpertises-cardsbody-check{
        width: 100%;
        padding: 30px 60px;
    }
    .cardspageexpertises-cardsbody-check h3{
        color: white;
    }
    .cardspageexpertises-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspageexpertises-cardsbody-textlist li{
        margin: 8px 0 8px 0;
    }
    .cardspageexpertises-cardsbody-checklist li{
        position: relative;
        list-style-type: none;
        padding-left: 2.5rem;
        margin-bottom: 0.5rem;
        color: white;
    }
    .cardspageexpertises-cardsbody-checklist li:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -2px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: white;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }

    .cardspageexpertises-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }
}

@media screen and (min-width: 768px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspageexpertises-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0c66ba;
    }

    .cardspageexpertises-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspageexpertises-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspageexpertises-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspageexpertises-cardsheader h1{
        color: white;
        font-size: 2.4rem;
        text-align: center;
        width: 70%;
    }
    .pageexpertises-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspageexpertises-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    .cardspageexpertises-cardsbody-text{
        width: 100%;
        padding: 30px 60px;
    }
    .cardspageexpertises-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.1rem;
    }

    .cardspageexpertises-cardsbody-check{
        width: 100%;
        padding: 30px 60px;
    }
    .cardspageexpertises-cardsbody-check h3{
        color: white;
    }
    .cardspageexpertises-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspageexpertises-cardsbody-textlist li{
        margin: 8px 0 8px 0;
    }
    .cardspageexpertises-cardsbody-checklist li{
        position: relative;
        list-style-type: none;
        padding-left: 2.5rem;
        margin-bottom: 0.5rem;
        color: white;
        font-size: 1.1rem;
    }
    .cardspageexpertises-cardsbody-checklist li:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -2px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: white;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }

    .cardspageexpertises-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }
}

@media screen and (min-width: 1024px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspageexpertises-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0c66ba;
    }

    .cardspageexpertises-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspageexpertises-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspageexpertises-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspageexpertises-cardsheader h1{
        color: white;
        font-size: 2.4rem;
        margin-left: 20px;
    }
    .pageexpertises-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspageexpertises-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .cardspageexpertises-cardsbody-text{
        width: 80%;
        padding: 25px 50px;
    }
    .cardspageexpertises-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.1rem;
    }

    .cardspageexpertises-cardsbody-check{
        padding: 25px 30px;
        width: 60%;
    }
    .cardspageexpertises-cardsbody-check h3{
        color: white;
    }
    .cardspageexpertises-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspageexpertises-cardsbody-textlist li{
        margin: 8px 0 8px 0;
    }
    .cardspageexpertises-cardsbody-checklist li{
        position: relative;
        list-style-type: none;
        padding-left: 2.5rem;
        margin-bottom: 0.5rem;
        color: white;
    }
    .cardspageexpertises-cardsbody-checklist li:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -2px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: white;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }

    .cardspageexpertises-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }
}

@media screen and (min-width: 1200px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspageexpertises-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0c66ba;
    }

    .cardspageexpertises-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspageexpertises-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspageexpertises-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspageexpertises-cardsheader h1{
        color: white;
        font-size: 2.4rem;
        margin-left: 20px;
    }
    .pageexpertises-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspageexpertises-cardsbody{
        height: 100%;
        width: 95%;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .cardspageexpertises-cardsbody-text{
        width: 100%;
        padding: 25px 50px;
    }
    .cardspageexpertises-cardsbody-text p{
        text-align: justify;
        color: white;
    }

    .cardspageexpertises-cardsbody-check{
        padding: 25px 60px;
        width: 60%;
    }
    .cardspageexpertises-cardsbody-check h3{
        color: white;
    }
    .cardspageexpertises-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspageexpertises-cardsbody-textlist{
        margin: 20px 0 20px 75px;
    }
    .cardspageexpertises-cardsbody-textlist li{
        margin: 8px 0 8px 0;
    }
    .cardspageexpertises-cardsbody-checklist li{
        position: relative;
        list-style-type: none;
        padding-left: 2.5rem;
        margin-bottom: 0.5rem;
        color: white;
    }
    .cardspageexpertises-cardsbody-checklist li:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -2px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: white;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }

    .cardspageexpertises-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }
}
@media screen and (min-width: 1440px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspageexpertises-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0c66ba;
    }

    .cardspageexpertises-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspageexpertises-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspageexpertises-cardsheader img{
        width: 100px;
        height: 100px;
    }
    .cardspageexpertises-cardsheader h1{
        color: white;
        font-size: 2.5rem;
        margin: 0;
    }
    .pageexpertises-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspageexpertises-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }
    .cardspageexpertises-cardsbody-text{
        width: 100%;
        padding: 25px 30px;
    }
    .cardspageexpertises-cardsbody-text p{
        text-align: justify;
        font-size: 1.3rem;
        color: white;
    }

    .cardspageexpertises-cardsbody-check{
        padding: 25px 60px;
        width: 60%;
    }
    .cardspageexpertises-cardsbody-check h3{
        color: white;
        font-size: 1.3rem;
    }
    .cardspageexpertises-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspageexpertises-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.2rem;
    }
    .cardspageexpertises-cardsbody-checklist li{
        position: relative;
        list-style-type: none;
        padding-left: 2.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.3rem;
        color: white;
    }
    .cardspageexpertises-cardsbody-checklist li:before{
        content: '';
        display: block;
        position: absolute;
        left: 1px;
        top: 3px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: white;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }

    .cardspageexpertises-seperate{
        width: 500px;
        height: 1px;
        margin: 50px 0 10px 0;
        background-color: white;
    }
}
@media screen and (min-width: 1920px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspageexpertises-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0c66ba;
    }

    .cardspageexpertises-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspageexpertises-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspageexpertises-cardsheader img{
        width: 125px;
        height: 125px;
    }
    .cardspageexpertises-cardsheader h1{
        color: white;
        font-size: 2.5rem;
        margin: 0;
    }
    .pageexpertises-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspageexpertises-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }
    .cardspageexpertises-cardsbody-text{
        width: 100%;
        padding: 25px 60px;
    }
    .cardspageexpertises-cardsbody-text p{
        text-align: justify;
        font-size: 1.4rem;
        color: white;
    }

    .cardspageexpertises-cardsbody-check{
        padding: 25px 60px;
        width: 60%;
    }
    .cardspageexpertises-cardsbody-check h3{
        color: white;
        font-size: 1.4rem;
    }
    .cardspageexpertises-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspageexpertises-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.3rem;
    }
    .cardspageexpertises-cardsbody-checklist li{
        position: relative;
        list-style-type: none;
        padding-left: 2.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
        color: white;
    }
    .cardspageexpertises-cardsbody-checklist li:before{
        content: '';
        display: block;
        position: absolute;
        left: 1px;
        top: 3px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: white;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }

    .cardspageexpertises-seperate{
        width: 500px;
        height: 1px;
        margin: 50px 0 10px 0;
        background-color: white;
    }
}