@media screen and (min-width: 320px){
    .join-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #0c66ba;
        width: 100%;
        height: 275px;
        box-shadow: 1px 5px 5px black;
    }
    #join-titre{
        color: white;
        text-align: center;
        margin: 15px 0 30px 0;
    }
    #join-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 50%;
        margin: 0 28px 0 28px;
    }
    .join-text-p{
        color: white;
        text-align: center;
    }
}
@media screen and (min-width: 480px){
    .join-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 275px;
        box-shadow: 1px 5px 5px black;
    }
    #join-titre{
        color: white;
        text-align: center;
        margin: 15px 0 30px 0;
    }
    #join-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 40%;
        margin: 0 28px 0 28px;
    }
    .join-text-p{
        color: white;
        text-align: center;
    }
}
@media screen and (min-width: 768px){
    .join-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 280px;
        box-shadow: 1px 5px 5px black;
    }
    #join-titre{
        color: white;
        text-align: center;
        margin: 15px 0 30px 0;
    }
    #join-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 40%;
        margin: 0 35px 0 35px;
    }
    .join-text-p{
        color: white;
        text-align: center;
        font-size: 1.3rem;
    }
}
@media screen and (min-width: 1024px){
    .join-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 280px;
        box-shadow: 1px 5px 5px black;
    }
    #join-titre{
        color: white;
        text-align: center;
        margin: 10px 0 30px 0;
        font-size: 2rem;
    }
    #join-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 40%;
        margin: 0 40px 0 40px;
    }
    .join-text-p{
        color: white;
        text-align: center;
        font-size: 1.4rem;
    }
}
@media screen and (min-width: 1200px){
    .join-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-shadow: 1px 5px 5px black;
    }
    #join-titre{
        color: white;
        text-align: center;
        margin: 0 0 17px 0;
        font-size: 1.7rem;
    }
    #join-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 40%;
        margin: 0 28px 0 28px;
    }
    .join-text-p{
        color: white;
        text-align: center;
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 1440px){
    .join-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-shadow: 1px 5px 5px black;
    }
    #join-titre{
        color: white;
        text-align: center;
        margin: 0 0 17px 0;
        font-size: 1.9rem;
    }
    #join-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 40%;
        margin: 0 28px 0 28px;
    }
    .join-text-p{
        color: white;
        text-align: center;
        font-size: 1.3rem;
    }
}
@media screen and (min-width: 1920px){
    .join-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-shadow: 1px 5px 5px black;
    }
    #join-titre{
        color: white;
        text-align: center;
        margin: 0 0 20px 0;
        font-size: 2rem;
    }
    #join-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 40%;
        margin: 0 30px 0 30px;
    }
    .join-text-p{
        color: white;
        text-align: center;
        font-size: 1.5rem;
    }
}