.header-navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    position: fixed;
    z-index: 20;
    width: 100%;
    transition: all 0.5s;
}

/* CSS actif quand scroll */
.header-navbar-scrolled{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    position: fixed;
    z-index: 5;
    width: 100%;
    color: whitesmoke;
    background-color: #0c66ba;
    box-shadow: 1px 5px 5px black;
    transition: all 0.5s;
    z-index: 20;
}
.header-navbar-scrolled .header-navbar-link-item-link,
.header-navbar-scrolled .navbar-dropdown-item-list-link{
    color: whitesmoke; /* Couleur lorsque la barre de navigation est scrollée */
  }
.header-navbar-scrolled img{
    content: url('../img/logos/logoC_white.png');
}

.header-navbar-logo{
    width: 12rem !important;
    height: 3.5rem !important;
    cursor: pointer;
    filter: brightness(1.3);
}

/* LIENS */
.header-navbar-menu{
    display: flex;
}


.header-navbar-link{
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    width: 100%;
}

.header-navbar-link-item{
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 5px 0 5px;
    padding-bottom: 2px;
    text-decoration: none;
}

.header-navbar-link-item-link{
    height: 20px;
    text-decoration: none;
    font-weight: bold;
    font-size: large;
    text-decoration: none;
    color: #0c66ba;
    cursor: pointer;
}
.header-navbar-link-item-link::after{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0); 
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0c66ba;
    transform-origin: bottom right;
    transition: transform 0.3s  ease-out;
}
.header-navbar-link-item-link:hover::after{
    transform: scaleX(1);
    transform-origin: bottom left;
}
.header-navbar-scrolled .header-navbar-link-item-link::after{
    background-color: whitesmoke;
}
/*
.header-navbar-link-item a.active{
    background-color: white;
}*/

.header-navbar-language-link{
    display: flex;
}

#header-navbar-language{
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    appearance:none;
    outline:0;
    box-shadow:none;
    border:0 !important;
    background: transparent;
    background-image: none;
    flex: 1;
    padding: 0;
    color:white;
    cursor:pointer;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
}
#header-navbar-language::-ms-expand {
    display: none;
}
.header-navbar-select{
    position: relative;
    display: flex;
    width: 8vh;
    align-items: center;
    background: transparent;
    overflow: hidden;
}
.header-navbar-select::after {
    content: '\25BC';
    position: absolute;
    padding: 0 3.3vh;
    background: transparent;
    color: white;
    cursor:pointer;
    pointer-events:none;
    transition: .25s all ease;
}
.header-navbar-select:hover::after {
    color: #0c66ba;
    opacity: 0.8;
}

#header-navbar-language option{
    color: black;
}

.nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

#navbar-button-contactus-link{
    text-decoration: none;
    color: white;
}

/* ---DROPDOWN--- */
.navbar-dropdown1{
    position: absolute;
    top: 3.2rem;
    width: 18vw;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all 0.3s ease-in;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    padding: 15px
}
.navbar-dropdown2{
    position: absolute;
    top: 3.2rem;
    width: 40vw;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all 0.3s ease-in;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    padding: 15px;
}
.navbar-dropdown3{
    position: absolute;
    top: 3.2rem;
    width: 30vw;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all 0.3s ease-in;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    padding: 15px;
}
.navbar-dropdown-item{
    color: #0d476b;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px 10px 0 10px;
}


.navbar-dropdown-inner-title-link{
    text-decoration: none;
}
.navbar-dropdown-inner-title:hover{
    color: #36cde7;
}
.navbar-dropdown-inner-title{
    margin: 0 0 10px 0;
    color: #10486b;
    cursor: pointer;
    font-size: 0.9rem;
}
.navbar-dropdown-inner-title-dropdown1{
    margin: 0 0 10px 0;
    color: white;
}
.navbar-dropdown-item-list{
    text-align: left;
    width: 100%;
    justify-content: flex-start;
}

.header-navbar-link-item:hover #navbar-down-icon {
    transition: all 0.3s ease-in;
    transform: rotate(180deg);
}
.navbar-dropdown-item-list-link{
    cursor: pointer;
    margin: 0;
    text-decoration: none;
    color: #0c66ba;
    font-size: 0.9rem;
}
.header-navbar-link-item:hover .navbar-dropdown1,
.header-navbar-link-item:hover .navbar-dropdown2,
.header-navbar-link-item:hover .navbar-dropdown3 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.navbar-dropdown-item-list-info{
    color: #0c66ba;
    text-align: left;
    margin: 5px 0 5px 0;
}
.navbar-dropdown-item-list-info:hover{
    color: #36cde7;
}


/* ---PARTIE RESPONSIVE--- */
@media screen and (min-width:320px){
    .navbar-dropdown1{
        width: 58vw;
        height: 140px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 90vw;
        height: 300px;
        padding: 10px;
        flex-direction: column;
    }
    .navbar-dropdown-item{
        margin-bottom: 10px;
    }
    .navbar-dropdown3{
        width: 90vw;
        height: 300px;
        padding: 10px;
        flex-direction: column;
    }
}
@media screen and (min-width:480px){
    .navbar-dropdown1{
        width: 43vw;
        height: 140px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 65vw;
        height: 300px;
        padding: 10px;
    }
    .navbar-dropdown3{
        width: 60vw;
        height: 360px;
        padding: 10px;
    }
}
@media screen and (min-width:768px){
    .navbar-dropdown1{
        width: 31vw;
        height: 145px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 65vw;
        height: 300px;
        padding: 10px;
    }
    .navbar-dropdown3{
        width: 50vw;
        height: 400px;
        padding: 10px;
    }
}
@media screen and (min-width:1024px){
    .navbar-dropdown1{
        width: 25vw;
        height: 130px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 50vw;
        height: 300px;
        padding: 10px;
    }
    .navbar-dropdown3{
        width: 38vw;
        height: 375px;
        padding: 10px;
    }
}
@media screen and (min-width:1275px){
    .navbar-dropdown1{
        width: 17vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 61vw;
        height: 140px;
        padding: 10px;
        flex-direction: row;
    }
    .navbar-dropdown-item{
        margin-bottom: 0;
    }
    .navbar-dropdown3{
        width: 41vw;
        height: 190px;
        padding: 10px;
        flex-direction: row;
    }
}
@media screen and (max-width:1275px){

    .header-navbar-logo{
        width: 10rem;
        height: 3.5rem;
        margin-right: 0.5rem;
    }

    .nav-btn{
        visibility: visible;
        opacity: 1;
    }

    .header-navbar-link{
        position: fixed;
        top: -100vh;
        left: 0;
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        backdrop-filter: blur(20px);
        /*background-color: #075787;*/
        transition: 1s;
    }

    .responsive_nav {
		transform: translateY(100vh);
	}

    .nav-close-btn{
        position: absolute;
        top: 1.5rem;
        right: 2rem;
    }

    #header-navbar-language{
        -webkit-appearance: none;
        appearance: none;
    }
}
@media screen and (min-width:1440px){
    .navbar-dropdown1{
        width: 15vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 50vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown3{
        width: 34vw;
        height: 175px;
        padding: 10px;
    }
}
@media screen and (min-width:1500px){
    .navbar-dropdown1{
        width: 15vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 48vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown3{
        width: 32vw;
        height: 175px;
        padding: 10px;
    }
}
@media screen and (min-width:1750px){
    .navbar-dropdown1{
        width: 13vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width: 41vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown3{
        width: 28vw;
        height: 180px;
        padding: 10px;
    }
}
@media screen and (min-width:1920px){
    .navbar-dropdown1{
        width: 12vw;
        height: 130px;
        padding: 10px;
    }
    .navbar-dropdown2{
        width:38vw;
        height: 135px;
        padding: 10px;
    }
    .navbar-dropdown3{
        width: 26vw;
        height: 175px;
        padding: 10px;
    }
}
@media screen and (max-width:1919px){
    .header-navbar-logo{
        width: 10rem;
        height: 3.5rem;
        margin-right: 0.5rem;
    }

}