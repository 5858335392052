.boxexpertises-container1:nth-child(1) .boxexpertises-container-hover1{
    width: 100%;
    height: 100%;
    background: url("../img/img/abonnement-magazine.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s;
    position: relative;
    opacity: 1;
}
.boxexpertises-container1:nth-child(2) .boxexpertises-container-hover1{
    width: 100%;
    height: 100%;
    background: url("../img/img/venteaunumero.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s;
    position: relative;
}
.boxexpertises-container1:nth-child(3) .boxexpertises-container-hover1{
    width: 100%;
    height: 100%;
    background: url("../img/img/Diffusion-presse-numerique.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s;
    position: relative;
}
.boxexpertises-container1:nth-child(4) .boxexpertises-container-hover1{
    width: 100%;
    height: 100%;
    background: url("../img/img/tirage-fab.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s;
    position: relative;
}
.boxexpertises-container1:nth-child(5) .boxexpertises-container-hover1{
    width: 100%;
    height: 100%;
    background: url("../img/img/Pubpresse.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s;
    position: relative;
}
.boxexpertises-container1:nth-child(n) .boxexpertises-container-hover1:hover{
    transform: scale(1.025);
    opacity: 1;
}

@media screen and (min-width: 320px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
    }
    .boxexpertises-maincontainer1{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
        vertical-align: middle;
    }
    /*.boxexpertises-container1:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #075787;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        background-color: white;
        border-radius: 4px;
        color: #075787;
        font-size: 0.5rem;
        padding: 1px;
        /*text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;*/
        text-align: center;
        transition: all 0.2s;
        position: absolute;
    }
    
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    }
    .activeexpertise1 h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}


@media screen and (min-width: 480px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90px;
    }
    .boxexpertises-maincontainer1{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
    }
    /*.boxexpertises-container1:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #075787;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        text-align: center;
        font-size: 0.9rem;
        padding: 3px;
        transition: all 0.2s;
        position: absolute;
    }
    
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    }
    .activeexpertise1 h4{
        color: white;
        background-color: #075787;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}

@media screen and (min-width: 768px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90px;
    }
    .boxexpertises-maincontainer1{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
    }
    /*.boxexpertises-container1:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #075787;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        text-align: center;
        padding: 4px;
        transition: all 0.2s;
        position: absolute;
    }
    
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    }
    .activeexpertise1 h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}


@media screen and (min-width: 1024px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 110px;
    }
    .boxexpertises-maincontainer1{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
    }
    /*.boxexpertises-container1:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #0c66ba;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        text-align: center;
        padding: 4px;
        transition: all 0.2s;
        position: absolute;
    }
    
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    }
    .activeexpertise1 h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}


@media screen and (min-width: 1200px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .boxexpertises-maincontainer1{
        display: grid;
        grid-template-columns: repeat(2, 0.5fr);
        grid-template-rows: 125px 125px 125px;
        justify-content: center;
        align-content: center;
        gap: 20px;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 300px;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
    }
    /*
    .boxexpertises-container:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #0c66ba;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        text-align: center;
        padding: 5px;
        font-size: 1rem;
        transition: all 0.2s;
        position: absolute;
    }
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    }
    .activeexpertise1 h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

}

@media screen and (min-width: 1440px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 135px;
    }
    .boxexpertises-maincontainer1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 135px 135px 135px;
        justify-content: center;
        align-content: center;
        gap: 20px;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 300px;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
    }
    /*.boxexpertises-container1:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #0c66ba;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        text-align: center;
        font-size: large;
        transition: all 0.2s;
        position: absolute;
    }
    
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        opacity: 1;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    }
    .activeexpertise1 h4{
        color: white;
        background-color: #0c66ba;
    }
    
    .boxexpertises-details{
        height: 600px;
    }
}

@media screen and (min-width: 1675px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 135px;
    }
    .boxexpertises-maincontainer1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 160px 160px 160px;
        justify-content: center;
        align-content: center;
        gap: 20px;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 380px;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
    }
    /*.boxexpertises-container1:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #0c66ba;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        text-align: center;
        font-size: large;
        transition: all 0.2s;
        position: absolute;
    }
    
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    }
    .activeexpertise1 h4{
        color: white;
        background-color: #0c66ba;
    }
    
    .boxexpertises-details{
        height: 600px;
    }
}

@media screen and (min-width: 1920px){

    .barselectexpertises-container1{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 160px;
    }
    .boxexpertises-maincontainer1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 160px 160px 160px;
        justify-content: center;
        align-content: center;
        gap: 20px;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 380px;
    }
    .boxexpertises-container1{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        overflow: hidden;
    }
    /*.boxexpertises-container1:hover{
        background-color: #075787;
    }*/
    .boxexpertises-container1:hover h4{
        color: white;
        background-color: #0c66ba;
        opacity: 1;
    }
    
    .boxexpertises-container1 h4{
        text-align: center;
        font-size: larger;
        transition: all 0.2s;
        position: absolute;
    }
    
    .boxexpertises_container_link1{
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    
    .activeexpertise1{
        background-color: transparent;
        opacity: 1;
    
    }
    .activeexpertise1 h4{
        color: white;
    }
    
    .boxexpertises-details{
        height: 600px;
    }

    
}

