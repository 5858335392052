.body-pagementions-container1-title{
    text-decoration: underline;
}

.body-pagementions{
    background-color: #0c66ba;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body-pagementions-text{
    margin: 25px 30px;
    color: white;
    font-size: 1.1rem;
}

.body-pagementions-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0 50px 0;
}

.body-pagementions-container-text{
    width: 90%;
    color: white;
    /*text-align: justify;*/
    margin: 25px;
}
.body-pagementions-container-text h2{
    text-decoration: underline;
}

.body-pagementions-container-infos{
    margin-top: 25px;
}

.body-pagementions-container-list{
    list-style-type: none
}
.body-pagementions-container-list li{
    margin: 15px;
}
