body{
    width: 100%;
}

.header{
    white-space: pre;
}

.header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title{
    color: #0c66ba;
}


.class-header-title{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 28%;
  margin-top: 100px;
  border: 5px solid white;
}
.class-header-title legend{
  position: relative;
  margin: auto;
  text-align: center;
  padding: 0 25px;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}
.class-header-title legend .class-header-title2{
  font-size: 2rem;
}
.class-header-title h1{
  font-size: 1.7rem;
  color: white;
}
.class-header-title p{
  font-size: 1.7rem;
  font-weight: bold;
  color: white;
}
.class-header-title-link{
  color: white;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: bold;
  transition: text-shadow 0.25s linear;
}
.class-header-title-link:hover{
  text-shadow: 0 0 10px white;
}



@media screen and (min-width: 320px){
  body{
    width: 100%;
    height: 145vh;
  }

  /*
  .header{
    height: 100vh;
  }*/

  .header-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: small;
  }

  h1.title{
    font-size: 1.3rem;
    text-align: center;
    padding: 2vh;
  }
}
@media screen and (min-width: 480px){
  body{
    width: 100%;
    height: 150vh;
  }

  /*
  .header{
    height: 100vh;
  }*/

  .header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  h1.title{
    font-size: 1.3rem;
    text-align: center;
  }
}
@media screen and (min-width: 768px){
  body{
    width: 100%;
    height: 150vh;
  }

  /*
  .header{
    height: 100vh;
  }*/

  .header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
  }

  h1.title{
    font-size: 2rem;
    text-align: center;
  }

  h2.title{
    margin: 10px 0 5px 0;
    font-size: 1.5rem;
  }
}
@media screen and (min-width:1024px){

  body{
    width: 100%;
    height: 155vh;
  }

  /*
  .header{
    height: 100vh;
  }*/

  .header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
  }

  h1.title{
    font-size: 2rem;
    text-align: center;
  }
  h2.title{
    margin: 10px 0 5px 0;
    font-size: 2rem;
  }
}
@media screen and (min-width:1200px){

  body{
    width: 100%;
    height: 140vh;
  }

  .header{
    height: 100vh;
  }

  .header-title{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    padding: 30px;
  }

  h1.title{
    font-size: 1.7rem;
    text-align: center;
    padding-top: 150px;
  }
  h2.title{
    margin: 0 0 20px 0;
    font-size: 1.5rem;
  }
  
}
@media screen and (min-width:1440px){

  body{
    width: 100%;
    height: 150vh;
  }

  .header{
    height: 100vh;
  }

  .header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
  }

  h1.title{
    font-size: 1.9rem;
    text-align: center;
  }

  h2.title{
    margin: 75px 0 0 0;
  }

}
@media screen and (min-width:1920px){

  body{
    width: 100%;
    height: 155vh;
  }

  .header{
    height: 100vh;
  }

  .header-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
  }

  h1.title{
    font-size: 2.1rem;
    text-align: center;
  }
}

/* SCROLL ANIMATION */

.arrow,
.arrow:before{
  position: absolute;
  left: 50%;
  cursor: pointer;
}

.arrow{
  width: 40px;
  height: 40px;
  top: 50%;
  margin: 50px 0 0 -20px;
  transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}
  
.arrow:before{
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;

}
  
@keyframes arrow{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
  
  
  