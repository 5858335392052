@media screen and (min-width: 320px){
    .cards2-domaines-left{
        margin: 20px 0 200px 10px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 300px;
        height: 200px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 150px;
        left: 25px;
        position: relative;
        width: 85%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        color: #0c66ba;
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}
@media screen and (min-width: 480px){
    .cards2-domaines-left{
        margin: 30px 0 225px 25px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 400px;
        height: 200px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 150px;
        left: 75px;
        position: relative;
        width: 75%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}
@media screen and (min-width: 768px){
    .cards2-domaines-left{
        margin: 30px 0 350px 125px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 600px;
        height: 300px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 225px;
        left: 200px;
        position: relative;
        width: 60%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}
@media screen and (min-width: 1024px){
    .cards2-domaines-left{
        margin: 30px 0 350px 125px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 600px;
        height: 300px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 225px;
        left: 400px;
        position: relative;
        width: 40%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}
@media screen and (min-width: 1200px){
    .cards2-domaines-left{
        margin: 30px 0 350px 125px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 600px;
        height: 300px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 225px;
        left: 400px;
        position: relative;
        width: 40%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}
@media screen and (min-width: 1440px){
    .cards2-domaines-left{
        margin: 30px 0 350px 125px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 650px;
        height: 350px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 275px;
        left: 400px;
        position: relative;
        width: 35%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}
@media screen and (min-width: 1750px){
    .cards2-domaines-left{
        margin: 30px 0 350px 150px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 700px;
        height: 400px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 325px;
        left: 400px;
        position: relative;
        width: 35%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}
@media screen and (min-width: 1920px){
    .cards2-domaines-left{
        margin: 50px 0 350px 150px;
        display: flex;
        flex-direction: row;
    }
    .cards2-domaines-img-left{
        width: 700px;
        height: 400px;
        border-radius: 10px;
        border: 3px solid white;
        position: absolute;
    }
    .cards2-domaines-container-left{
        top: 325px;
        left: 400px;
        position: relative;
        width: 35%;
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        /*border: 3px solid #2FCBFF;*/
        font-weight: bold;
    }
    .cards2-domaines-title{
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cards2-domaines-texts{
        font-size: calc(0.8rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: justify;
        height: 20%;
    }
}